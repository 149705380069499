import React, { useEffect } from 'react'
import { navigate } from 'gatsby'

import useAuth from '../../util/useAuth'

function logout() {
  const { logout } = useAuth()
  useEffect(() => {
    logout()
    navigate('/auth')
  }, [])
  return <div>Logging out...</div>
}

export default logout
